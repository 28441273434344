import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import Layout from '../components/layout';
import BackgroundImage from 'gatsby-background-image';

class NotFound extends React.Component {
  render() {
    const page = get(this, 'props.data.contentfulSeite');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <div style={{ background: '#fff' }}>
          <SEO title="Seite nicht gefunden" />
          <BackgroundImage 
            backgroundColor="#0e3920"
            className="header"
            fluid={page.heroImage.fluid} 
          >
            <h1>404</h1>
          </BackgroundImage>
          <div className="container">
            <h2>Seite wurde nicht gefunden.</h2>
            <Link to="/" style={{ textAlign: 'center', display: 'block' }}>Zur Startseite</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFound;

export const pageQuery = graphql`
  query NotFoundQuery {
    contentfulSeite(slug: { eq: "ueber-uns" }) {
      heroImage {
        fluid(quality: 100, maxWidth: 1920, cropFocus: TOP) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
